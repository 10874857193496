import styles from './ArticlesAdminPanel.module.css';
import React from 'react';
import cn from 'classnames';

import { ToastContainer, toast } from 'react-toastify';
import { Skeleton, Button } from 'antd';
import { adminkaDeleteArticle, adminkaDeleteComment, adminkaGetArticles } from '../../../services/user.service';
import { NewsArticle } from '../../../Interfaces/types';
import { getPublishString } from '../../../Utils/UnixTimeConverter';


const ArticlesAdminPanelPage: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [articles, setArticles] = React.useState(new Array<NewsArticle>());
  
  React.useEffect(() => {
    setLoaded(false);

    adminkaGetArticles().then((result) => {
      setArticles(result);
    }).catch(() => {
      toast('Ошибка загрузки списка статей!');
    }).finally(() => {
      setLoaded(true);
    })
  }, []);

  const removeArticleCallback = React.useCallback((articleId: number) => {
    toast("Удаляем новость...");
    
    adminkaDeleteArticle(articleId).then(() => {
      toast("Новость успешно удалена.");
      setArticles(articles.filter(x => x.id !== articleId));
    }).catch(() => {
      toast("Ошибка удаления новости!");
    })
  }, [articles]);

  const removeCommentCallback = React.useCallback((articleId: number, commentId: number) => {
    toast("Удаляем комментерий...");
    
    adminkaDeleteComment(commentId).then(() => {
      const findArticle = articles.filter(x => x.id === articleId)[0];
      findArticle.comments = findArticle.comments.filter(x => x.id !== commentId);
      setArticles(articles.map(x => (x.id === findArticle.id ? findArticle : x)));
      toast("Комментарий удален!");
    }).catch(() => {
      toast("Ошибка удаления комментария!");
    })
  }, [articles]);

  return (
    <div className={styles.container}>
      <div>Управление статьями и комментариями:</div>
      {  
        loaded ?
          <>
            { 
              articles.map((article, index) => 
                <div key={index} className={styles.article_container}>
                  <div>
                    Новость #{index+1}: {article.title}
                  </div>
                  <div>
                    <Button
                      style={{ background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)', border: 'none' }} 
                      size="middle" 
                      type="primary"
                      onClick={() => removeArticleCallback(article.id)}
                    >
                      Удалить
                    </Button>
                  </div>
                  
                  <div className={cn(styles.comments_item, styles.comments_header)}>
                    <div className={styles.comments_number}>
                      Номер
                    </div>
                    <div className={styles.comments_author}>
                      Автор
                    </div>
                    <div className={styles.comments_text}>
                      Текст комментария
                    </div>
                    <div className={styles.comments_date}>
                      Дата
                    </div>
                    <div className={styles.comments_buttons}>
                      Управление
                    </div>
                  </div>
                  {
                    article.comments.length > 0 ? article.comments.map((comment, commentIndex) => 
                      <div className={styles.comments_item}>
                        <div className={styles.comments_number}>
                          { commentIndex+1 }
                        </div>
                        <div className={styles.comments_author}>
                          { comment.authorName }
                        </div>
                        <div className={styles.comments_text}>
                          { comment.text }
                        </div>
                        <div className={styles.comments_date}>
                          { getPublishString(comment.unixDate) }
                        </div>
                        <div className={styles.comments_buttons}>
                          <Button
                            style={{ background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)', border: 'none' }} 
                            size="middle" 
                            type="primary"
                            onClick={() => removeCommentCallback(article.id, comment.id)}
                          >
                            Удалить
                          </Button>
                        </div>
                      </div>) : <div>Комментариев нет</div>
                  }
                  
                </div>
              ) 
            }
          </> 
          : <Skeleton paragraph={{ rows: 5 }} /> }
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ArticlesAdminPanelPage;