import React from 'react';
import styles from './ScheduleCoursePairs.module.css';
import { Link } from "react-router-dom";
import cn from 'classnames';
import { ScheduleCoursePairsResponce } from '../../Interfaces/types';
import { getScheduleCoursePairs } from '../../services/user.service';
import { Skeleton } from 'antd';

interface ScheduleCoursePairsProps {
  id: number;
}

const ScheduleCoursePairs: React.FC<ScheduleCoursePairsProps> = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [result, setResult] = React.useState<ScheduleCoursePairsResponce>();

  React.useEffect(() => {
    async function fetchIt() {
      const data = await getScheduleCoursePairs(props.id);
      setResult(data);
      setLoaded(true);
    }
    
    fetchIt();
  }, [props.id]);
  
  return (
    <div className={styles.container}>
      <div className={styles.title_back}>
          <Link to="/students/rasp"> {'<'} Вернуться к группам</Link>
      </div>
      <div className={styles.title_text}>
          { loaded ? <span>{result?.KursName}, Группа: {result?.GroupName}</span> : <span>Группа:</span>}
      </div>
      <div className={styles.rasp}>
          { loaded ? 
              <> 
                { 
                  result?.Days.map(day => 
                    <div className={styles.day_container}>
                      <div className={styles.rasp_day_title}>
                        {day.DayName}
                      </div>
                      <div className={cn(styles.rasp_item, styles.rasp_header)}>
                        <div className={styles.rasp_item_number}>
                          № пары
                        </div>
                        <div className={styles.rasp_item_text}>
                          Предмет
                        </div>
                        <div className={styles.rasp_item_place}>
                          Аудитория
                        </div>
                      </div>
                      { 
                        day.Pairs.map(pair => 
                          <div className={styles.rasp_item}>
                            <div className={styles.rasp_item_number}>
                              { pair.Number }
                            </div>
                            <div className={styles.rasp_item_text}>
                              { pair.Name }
                            </div>
                            <div className={styles.rasp_item_place}>
                              { pair.Place }
                            </div>
                          </div>) 
                      }
                    </div>
                  ) 
                } 
              </> : 
              <Skeleton paragraph={{ rows: 20 }} />
          }
        </div>
    </div>
  );
}

export default ScheduleCoursePairs;