import styles from './LatestNews.module.css';
import cn from 'classnames';
import React from 'react';

import { NavLink } from "react-router-dom";
import { getLatestNews } from '../../services/user.service';
import { Skeleton } from 'antd';
import { getPublishString } from '../../Utils/UnixTimeConverter';
import { NewsArticle } from '../../Interfaces/types';

const LatestNews: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  
  const [articles, setArticles] = React.useState(new Array<NewsArticle>());
  
  React.useEffect(() => {
    async function fetchIt() {
      getLatestNews().then((result) => {
        setArticles(result);
      }).catch(() => {
        
      }).finally(() => {
        setLoaded(true);
      });
    }
    
    fetchIt();
  }, []);
  
  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <div className={styles.title}>
          Последние новости
        </div>
        <div>
          <NavLink to="/news">
            <div className={cn(styles.button, styles.news_button)}> 
              Все новости
            </div>
          </NavLink>
        </div>
      </div>
      <div className={styles.news_container}>
        { loaded ? articles.map(x => 
          <NavLink key={x.id} to={`/news/${x.id}`}>
            <div className={styles.news_item}>
              <div className={styles.image_filter}>
                {/* накладываем фильтр на фото */}
              </div>
              <div className={styles.info}>
                <div className={styles.info_date}>
                  { getPublishString(x.unixDate, true) }
                </div>
                <div className={styles.info_title}>
                  { x.title }
                </div>
              </div>
              <img src={x.posterLink} alt="Poster"/>
            </div>
          </NavLink>) : 
          <>
            <div className={styles.news_item}>
              <Skeleton paragraph={{ rows: 7 }} />
            </div>
            <div className={styles.news_item}>
              <Skeleton paragraph={{ rows: 7 }} />
            </div>
            <div className={styles.news_item}>
              <Skeleton paragraph={{ rows: 7 }} />
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default LatestNews;
