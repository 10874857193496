export enum EAction {
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  REGISTER_FAIL = "REGISTER_FAIL",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  LOGOUT = "LOGOUT",
  SET_MESSAGE = "SET_MESSAGE",
  CLEAR_MESSAGE = "CLEAR_MESSAGE"
}

export interface Action {
  type: string,
  payload?: object
};

const registerSuccessAction: Action = {
  type: EAction.REGISTER_SUCCESS
};

const registerFailAction: Action = {
  type: EAction.REGISTER_FAIL
};

const loginSuccessAction = (payload: LoginSuccessPayload): Action => ({
  type: EAction.LOGIN_SUCCESS,
  payload
});

const loginFailAction: Action = {
  type: EAction.LOGIN_FAIL
}

const logOutAction: Action = {
  type: EAction.LOGOUT
}

const setMessageAction = (payload: SetMessagePayload): Action => ({
  type: EAction.SET_MESSAGE,
  payload
});

export {
  registerSuccessAction,
  registerFailAction,
  loginSuccessAction,
  loginFailAction,
  logOutAction,
  setMessageAction,
}

export interface SetMessagePayload {
  message: string
}

export interface LoginSuccessPayload {
  user: LoginResult
}



export interface LoginResult {
  id: number,
  username?: string,
  email?: string,
  roles: string[],
  accessToken?: string,
  message: string
}