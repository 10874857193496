import { Action, EAction, LoginResult, LoginSuccessPayload } from "../actions/types";

import { logout } from "../../services/auth.service";
import { isTokenExpired } from "../../Utils/JwtUtils";

const loadSavedToken = () => {
  const result = JSON.parse(localStorage.getItem("user") || '{ }');

  if (result.accessToken) {
    if (isTokenExpired(result.accessToken))
    {
      // истек срок действия токена. 
      // удаляем localstorage данные и разлогиниваем пользователя
      logout();

      return { };
    }
  }

  return result;
}

const user: LoginResult = loadSavedToken();

const initialState = user.accessToken
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const reducer = (state = initialState, action: Action) => {
  switch(action.type) {
    case EAction.REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    
    case EAction.REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case EAction.LOGIN_SUCCESS:
      const loginSuccessPayload = action.payload as LoginSuccessPayload;
      return {
        ...state,
        isLoggedIn: true,
        user: loginSuccessPayload.user,
      };
    case EAction.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case EAction.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}

export default reducer;