import styles from './ArticlePreview.module.css';
import { getPublishString } from "../../Utils/UnixTimeConverter";
import { Space } from 'antd';
import { NavLink } from "react-router-dom";
import { NewsArticlePreview } from '../../Interfaces/types';

const ArticlePreivew: React.FC<NewsArticlePreview> = props => {
  return (
    <NavLink className={styles.link} to={`/news/${props.id}`}>
      <div className={styles.article}>
        <div className={styles.poster}>
          <img src={props.posterLink} alt="Фото"/>
        </div>
        <Space direction="vertical">
          <div className={styles.title}>
            {props.title}
          </div>
          <div className={styles.preview}>
            { props.previewText }
          </div>
          <div>{getPublishString(props.unixDate)}</div>
        </Space>
      </div>
    </NavLink>
  );
}

export default ArticlePreivew;