import styles from './RegisterPage.module.css';
import React from 'react';
import cn from 'classnames';

import { Form, Input, Button } from 'antd';
import { NavLink, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { doRegister } from '../../redux/actions/auth';
import { LoginResult } from '../../redux/actions/types';
import { ToastContainer, toast } from 'react-toastify';

interface RegisterFormData {
  email: string,
  username: string,
  password: string,
  password_doublecheck: string;
}

interface RegisterProps {
  message: string,
  user: LoginResult | null,
  doRegister: (username: string, email: string, password: string) => Promise<void>
}

const RegisterPage: React.FC<RegisterProps> = (props) => {
  const currentUser = React.useMemo(() => props.user, [props.user]);

  const onFinish = (values: RegisterFormData) => {
    if (values.password !== values.password_doublecheck) {
      toast("Ошибка регистрации! Пароли не совпадают!");
      return;
    }
    if (values.password.length < 8) {
      toast("Ошибка регистрации! Пароль должен состоять как минимум из 8 символов!");
      return;
    }

    props.doRegister(values.username, values.email, values.password).then(() => {
      toast("Вы успешно зарегистрировались! Теперь вы можете войти на сайт.");
    }).catch((error) => {
      toast("Ошибка регистрации! " + error);
    });
  };

  if (currentUser && currentUser.username) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className={styles.register_page}>
      <div>
        <div className={styles.form_container}>
        <Form
            style={{color: 'red'}}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <span className={cn(styles.label, styles.title)}>Регистрация:</span>

            <span className={styles.label}>Почта:</span>  

            <Form.Item name="email" rules={[{ type: 'email', required: true, message: 'Пожалуйста введите корректную почту!' }]} >
              <Input 
                style={{ backgroundColor: 'transparent' }}
                size="large" 
                placeholder="" />
            </Form.Item>

            <span className={styles.label}>Логин:</span>  

            <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста введите логин!' }]} >
              <Input 
                style={{ backgroundColor: 'transparent' }}
                size="large" 
                placeholder="" />
            </Form.Item>
            
            <span className={styles.label}>Пароль:</span>  
            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }}
                size="large"
                type="password"
                placeholder=""
              />
            </Form.Item>
            <span className={styles.label}>Пароль ещё раз:</span>  
            <Form.Item name="password_doublecheck" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }} 
                size="large"
                type="password"
                placeholder=""
              />
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button 
              style={{ width: '100%', background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)', border: 'none' }} size="large" type="primary" htmlType="submit" className="login-form-button">
                Зарегистрироваться
              </Button>

              <NavLink className="login-form-forgot" to="/login">
                <span className={styles.bigger}>Уже зарегистрированы?</span>
              </NavLink>
            </Form.Item>
          </Form>
          <ToastContainer autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

type RegisterState = {
  message: string,
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): RegisterState => ({
  message: state.message,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  doRegister: (username: string, email: string, password: string): Promise<void> => dispatch(doRegister(username, email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);