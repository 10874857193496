import styles from './ArticleFull.module.css';
import React from 'react';

import { Comment, NewsArticle } from '../../Interfaces/types';
import { getPublishString } from '../../Utils/UnixTimeConverter';
import { LoginResult } from '../../redux/actions/types';
import { Form, Input, Button, Skeleton } from 'antd';
import { getSelectedNews, sendNewComment } from '../../services/user.service';
import { ToastContainer, toast } from 'react-toastify';

const { TextArea } = Input;

interface ActicleFullProps {
  id: number,
  user: LoginResult | null
}

interface NewCommentFormData {
  text: string
}

const defaultArticle: NewsArticle = {
  title: '',
  id: -1,
  html: '',
  unixDate: 0,
  previewText: '',
  posterLink: '',
  comments: []
}

const ArticleFull: React.FC<ActicleFullProps> = props => {
  const [loaded, setLoaded] = React.useState(false);
  const [article, setArticle] = React.useState<NewsArticle>(defaultArticle);
  const [comments, setComments] = React.useState(new Array<Comment>());

  const textAreaRef = React.useRef<HTMLInputElement>(null);

  const currentUser = React.useMemo(() => props.user, [props.user]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    async function fetchIt() {
      setLoaded(false);

      getSelectedNews(props.id).then(result => {
        setArticle(result);
        setComments(result.comments);
      }).catch(() => {
        toast("Ошибка загрузки новости!");
      }).finally(() => {
        setLoaded(true);
      });
    }
    
    fetchIt();
  }, [props.id]);

  const onCommentSendCallback = React.useCallback((values: NewCommentFormData) => {
    sendNewComment(props.id, values.text, currentUser?.username).then((newComment) => {
      toast("Комменатрий успешно отправлен!");

      form.resetFields();

      setComments([newComment, ...comments]);
    }).catch(() => {
      toast("Ошибка отправки комментария!");
    })
  }, [currentUser?.username, comments, props.id, form]);

  return loaded ?
    <>
      <div className={styles.target_title}>
        {article.title}
      </div>
      <div className={styles.target_date}>
        { getPublishString(article.unixDate) }
      </div>
      <div className={styles.target_html} dangerouslySetInnerHTML={ { __html: article.html }}></div>
      <div>
      {
        (currentUser && currentUser?.username) && 
          <div className={styles.comment_form_container}>
            <h2>Добавить комментарий</h2>
            <Form
              name="normal_login"
              className="login-form"
              onFinish={onCommentSendCallback}
              form={form}
            >
              <Form.Item 
                name="text" 
                style={{ marginBottom: "10px" }}
                rules={[{ required: true, message: 'Пожалуйста введите комментарий!' }]} 
              >
                <TextArea
                  ref={textAreaRef}
                  className={styles.comment_textarea}
                  rows={2}
                  size="large"
                  placeholder="Ваш комментарий" 
                  style={{ background: 'transparent' }}
                />        
              </Form.Item>

              <div className={styles.form_controls}>
                <div>
                  <Button
                    style={{ 
                      backgroundColor:'#005FAA',
                      border: 'none'
                    }}
                    size="large" 
                    type="primary" 
                    htmlType="submit" 
                  >
                    Отправить
                  </Button>
                </div>
              </div> 
            </Form>
          </div>
        }
        {
          comments.length > 0 && 
            <div className={styles.comments_count}>
              <h2>
                Комментарии
                <span className={styles.counter}>
                  &nbsp;{comments.length}
                </span>
              </h2>
            </div>
        }
        { 
          comments.map((comment, index ) => 
            <div key={`post${index}`} className={styles.comment}>
              <div className={styles.comment_title}>
                { comment.authorName }
              </div>
              <div className={styles.comment_text}>
                { comment.text }
              </div>
              <div className={styles.comment_date}>
                { getPublishString(comment.unixDate) }
              </div>
            </div>
          ) 
        }

        <ToastContainer autoClose={5000} />
      </div>
    </>
    : <Skeleton paragraph={{ rows: 24 }} />;
}

export default ArticleFull;