import React from 'react';
import { adminkaGetFeedbacks } from '../../../services/user.service';
import { Skeleton } from 'antd';

import styles from './FeedbacksAdminPanel.module.css';
import { FeedbackType } from '../../../Interfaces/types';
import cn from 'classnames';
import { getPublishString } from '../../../Utils/UnixTimeConverter';

import { ToastContainer, toast } from 'react-toastify';

const FeedbacksAdminPanel: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [feedbacks, setFeedbacks] = React.useState(new Array<FeedbackType>());

  React.useEffect(() => {
    setLoaded(false);
    adminkaGetFeedbacks().then((result) => {
      setFeedbacks(result);
    }).catch(() => {
      toast("Ошибка загрузки фидбеков!");
    }).finally(() => {
      setLoaded(true);
    })
  }, []);
  
  return (
    <div>
      <div>
        Список фидбеков:
      </div>
      {
        loaded ? 
          <>
            {
              feedbacks.length > 0 ? 
                <>
                  <div className={cn(styles.feedback_item, styles.feedback_header)}>
                    <div className={styles.feedback_id}>
                      ID
                    </div>
                    <div className={styles.feedback_name}>
                      Имя
                    </div>
                    <div className={styles.feedback_email}>
                      Email
                    </div>
                    <div className={styles.feedback_text}>
                      Текст
                    </div>
                    <div className={styles.feedback_data}>
                      Дата
                    </div>
                  </div>
                  { 
                    feedbacks.map(x => 
                      <div className={styles.feedback_item}>
                        <div className={styles.feedback_id}>
                          {x.id}
                        </div>
                        <div className={styles.feedback_name}>
                          {x.authorName}
                        </div>
                        <div className={styles.feedback_email}>
                          {x.authorEmail}
                        </div>
                        <div className={styles.feedback_text}>
                          {x.text}
                        </div>
                        <div className={styles.feedback_data}>
                          { getPublishString(x.unixDate) }
                        </div>
                      </div>
                    ) 
                  } 
            </> : <span>Пока ничего нет.</span>
            } 
            
          </> : <Skeleton paragraph={{ rows: 5 }} />
      }
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default FeedbacksAdminPanel;