import { Carousel as CarouselAnt } from 'antd';
import { elementList } from '../../data';
import { convertToDivs } from '../../Utils';

import styles from './CarouselElement.module.css';

const CarouselElement: React.FC = () => {
  return (
    <div className={styles.container}>
      <CarouselAnt autoplay>
        {
          elementList.map(news => 
            <div>
              <div className={styles.control}>
                <div className={styles.lside}>
                  <div className={styles.title}>
                    { convertToDivs(news.title) }
                  </div> 
                  <div className={styles.text}>
                    { convertToDivs(news.text) }
                  </div>
                </div>
                <div className={styles.rside}>
                  <div className={styles.rside_image}>
                    <img src={news.image_url} alt="Header1"/>
                  </div>
                </div>
              </div>
            </div>)
        }
      </CarouselAnt>
    </div>
  );
};

export default CarouselElement;