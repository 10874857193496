import { Action, EAction, SetMessagePayload } from "../actions/types";

const reducer = (state: string = '', action: Action) => {
  switch(action.type) {
    case EAction.SET_MESSAGE: 
      const setMessagePayload = action.payload as SetMessagePayload; 
      return setMessagePayload.message;

    case EAction.CLEAR_MESSAGE: 
      return '';

    default:
      return state;
  }
}

export default reducer;