export const getCurrentUnixTimeSeconds = () => {
  return Math.floor(+new Date() / 1000);
}
export const getCurrentUnixTimeSecondsUTC = () => {
  return Math.floor(+new Date().getTime() / 1000);
}

const monthNames = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня",
  "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"
];

export const getPublishString = (unixTime: number, onlyDMY = false) => {
  const fixedLengthString = (val: number): string => {
    return val.toString().padStart(2, '0');
  }
  
  const diff = getCurrentUnixTimeSeconds() - unixTime;

  const daySeconds = 86400;
  const daysAgo = Math.floor(diff / daySeconds);

  const date = new Date(unixTime * 1000);
  const publishTime = `${fixedLengthString(date.getHours())}:${fixedLengthString(date.getMinutes())}`;

  if (onlyDMY) { // нужен формат dd/mm/yyyy
    return date.toLocaleString().split(',')[0]
  }

  switch(daysAgo) {
    case 0: return `сегодня в ${publishTime}`;
    case 1: return `вчера в ${publishTime}`
    default: return `${date.getDay()} ${monthNames[date.getMonth()].toLowerCase()} ${date.getFullYear()} в ${publishTime}`; 
  }
}

