
const convertToDivs = (text: string) => (
  <>
    {
      splitLines(text).map(x => <div>{x.trim()}</div>)
      //text.split('\n').map(x => <div>{x.trim()}</div>)
    }   
  </>
)

const splitLines = (text: string): string[] => {
  if (text.includes('<br/>')) {
    return text.split('<br/>');
  } else if (text.includes('\n')) {
    return text.split('\n');
  } else {
    return new Array<string>(text);
  }
}

// создаем превью не более чем 100 символов
const getArticlePreviewText = (text: string) => {
  const plainWordsArray: string[] = text.split(' ');

  let currentLen = 0;
  let _previewText = '';

  for(let i = 0; i < plainWordsArray.length; i++) {
    const currentWord = plainWordsArray[i];

    if (currentLen + currentWord.length < 100) {
      _previewText += `${currentWord} `;
      currentLen += currentWord.length;
    } else { break }
  }

  return `${_previewText.trim()}...`;
}

export {
  convertToDivs,
  getArticlePreviewText
}