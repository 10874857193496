import styles from './LoginPage.module.css';
import React from 'react';
import cn from 'classnames';

import { Form, Input, Button } from 'antd';
import { useHistory, Redirect } from "react-router-dom";
import { RootState } from '../../redux/store/store';
import { doLogin } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { LoginResult } from '../../redux/actions/types';
import { ToastContainer, toast } from 'react-toastify';

interface FormData {
  username: string,
  password: string
}

interface LoginPageProps {
  message: string,
  user: LoginResult | null,
  doLogin: (username: string, password: string) => Promise<void>
}

const LoginPage: React.FC<LoginPageProps> = (props) => {  
  const currentUser = React.useMemo(() => props.user, [props.user]);
  const history = useHistory();
  
  const onFinish = async (values: FormData) => {
    props.doLogin(values.username, values.password).then(() => {
      history.push('/profile');   
    }).catch((error) => {
      toast(error);
    });
  };

  if (currentUser && currentUser.username) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className={styles.login_page}>
      <div>
        <div className={styles.form_container}>
          <Form
            style={{color: 'red'}}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <span className={cn(styles.label, styles.title)}>Вход в учетную запись:</span>

            <span className={styles.label}>Логин:</span>

            <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста введите логин!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }}
                size="large" 
                placeholder="" />
            </Form.Item>

            <span className={styles.label}>Пароль:</span>  
            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }}
                size="large"
                type="password"
                placeholder=""
              />
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
               style={{ width: '100%', background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)', border: 'none' }} 
               size="large" 
               type="primary" 
               htmlType="submit" 
               className="login-form-button">
                Войти
              </Button>
            </Form.Item>
          </Form>
          <ToastContainer autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

type LoginState = {
  message: string,
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): LoginState => ({
  message: state.message,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (username: string, password: string): Promise<void> => dispatch(doLogin(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

