import styles from './FeedbackPage.module.css';
import React from 'react';
import cn from 'classnames';

import { Form, Input, Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { sendFeedback } from '../../services/user.service';

const { TextArea } = Input;

interface FeedbackFormData {
  email: string,
  name: string,
  text: string
}

const FeedbackPage: React.FC = () => { 
  const [form] = Form.useForm();
  
  const onFinish = React.useCallback((formData: FeedbackFormData) => {
    sendFeedback(formData.name, formData.email, formData.text).then(() => {
      toast('Спасибо! Мы свяжемся с вами в ближайшее время.');
      form.resetFields();
    }).catch(() => {
      toast('Ошибка отправки сообщения. Пожалуйста, повторите позже.');
    })
  }, [form]);
  
  return (
    <div className={styles.feedback_page}>
      <div>
        <div className={styles.form_container}>
          <Form
            form={form}
            style={{color: 'red'}}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <span className={cn(styles.label, styles.title)}>
              Остались вопросы?
            </span>
            <span className={cn(styles.label, styles.subtitle)}>
              Напишите нам и мы обязательно вам ответим!
            </span>

            <span className={styles.label}>Ваша почта:</span>

            <Form.Item name="email" rules={[{ required: true, message: 'Пожалуйста заполните поле!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }}
                size="large"
                type="email" 
                placeholder="" />
            </Form.Item>

            <span className={styles.label}>Как к Вам обращаться:</span>  
            <Form.Item name="name" rules={[{ required: true, message: 'Пожалуйста заполните поле!' }]} >
              <Input
                style={{ backgroundColor: 'transparent' }}
                size="large"
                placeholder=""
              />
            </Form.Item>  

            <span className={styles.label}>Ваше сообщение:</span>
            <Form.Item name="text" rules={[{ required: true, message: 'Пожалуйста заполните поле!' }]} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <TextArea
                  rows={2}
                  size="large"
                  placeholder="" 
                  style={{ background: 'transparent', resize: 'none' }}
                />   
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
               style={{ width: '100%', background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)', border: 'none' }} 
               size="large" 
               type="primary" 
               htmlType="submit" 
               className="login-form-button"
              >
                Отправить
              </Button>
            </Form.Item>

          </Form>
          <ToastContainer autoClose={5000} />
        </div>
      </div>
    </div>
  );
}

export default FeedbackPage;
