import styles from './NotFoundPage.module.css';
import cn from 'classnames';
import { Link } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.big_text}>
        ;( 404
      </div>
      <div className={styles.label}>
        Упс, что-то пошло не так... страница не найдена
      </div>
      <div className={styles.button_container}>
        <Link to="/" className={cn(styles.button, styles.home_button)}>
          Вернуться на главную
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage;