import styles from './Footer.module.css';
import cn from 'classnames';

import { convertToDivs } from '../../Utils';
import { contactsList } from '../../data';

const Footer: React.FC = () => (
  <div className={styles.footer}>
    <div className={styles.footer_content}>
      <div className={styles.row}>
        <div>
          <div className={styles.logo}>&nbsp;</div>
          <div className={styles.social}>Мы в соцсетях</div>
          <div>
            <a href="https://vk.com/urtisi" target="_blank" rel="noreferrer">
              <input type="button" className={cn(styles.button, styles.button_vk)} />
            </a>
            <a href="https://www.instagram.com/urtisi_sibguti/" target="_blank" rel="noreferrer">
              <input type="button" className={cn(styles.button, styles.button_instagram)} />
            </a>
          </div>
        </div>
        <div className={styles.contacts}>
          { contactsList.map((contact, index) => 
            <div key={`contact${index}`} className={contact.isAddress ? cn(styles.contact_item, styles.address) : styles.contact_item}>
              <div>
                { convertToDivs(contact.title) }
              </div>
              <div className={styles.contact_text}>
                { convertToDivs(contact.text) }
              </div>
            </div>
          ) }
         </div> 
       </div>
     </div>
  </div>
);

export default Footer;