export enum EStatusCode {
  OK = 'OK',
  MissingParam = 'MissingParam',
  MissingToken = 'MissingToken',
  InvalidToken = 'InvalidToken',
  EmailTaken = 'EmailTaken',
  UsernameTaken = 'UsernameTaken',
  InvalidUsernameOrPassword = 'InvalidUsernameOrPassword'
}

export interface RequestStatus {
  status_code: EStatusCode,
  result?: any,
  param?: string
}

export interface LoginApiResult {
  id: number,
  username: string,
  email: string,
  roles: string[],
  accessToken: string
}

// новость полностью
export interface NewsArticle {
  id: number,
  title: string,
  posterLink: string,
  unixDate: number,
  html: string,
  previewText: string,
  comments: Comment[]
}

// превью новости
export interface NewsArticlePreview {
  id: number,
  title: string,
  posterLink: string,
  unixDate: number,
  previewText: string
}

export interface Comment {
  id: number,
  text: string,
  authorName: string,
  authorAvatar: string,
  unixDate: number
}

// название курса и массив групп
export interface ScheduleCoursesResult {
  kursName: string
  groups: ScheduleGroupInfo[]
}
interface ScheduleGroupInfo {
  groupName: string,
  index: number
}

// пары конкретной группы
export interface ScheduleCoursePairsResponce {
  GroupName: string,
  KursName: string,
  Days: ScheduleCoursePairsDay[]
}
interface ScheduleCoursePairsDay {
  DayName: string,
  Pairs: ScheduleCoursePair[] 
}
interface ScheduleCoursePair {
  Number: number,
  Name: string,
  Place: string
}

// feedback type
export interface FeedbackType {
  id: number,
  authorName: string,
  authorEmail: string,
  text: string,
  unixDate: number
}
