import jwt_decode from "jwt-decode";
import { getCurrentUnixTimeSecondsUTC } from "./UnixTimeConverter";

const isTokenExpired = (accessToken: string): boolean => {
  // у нас есть сохраненный токен. проверяем испортился ли он
  const expTime = jwt_decode<any>(accessToken).exp;
  const currTime = getCurrentUnixTimeSecondsUTC();

  return currTime > expTime;
}

export {
  isTokenExpired
}