import styles from './ScheduleCourses.module.css';
import React from 'react';
import cn from 'classnames';
import { Link } from "react-router-dom";
import { Skeleton } from 'antd';
import { ScheduleCoursesResult } from '../../Interfaces/types';
import { getScheduleCourses } from '../../services/user.service';

const ScheduleCourses: React.FC = () => { 
  const [loaded, setLoaded] = React.useState(false);
  const [kurses, setKurses] = React.useState(new Array<ScheduleCoursesResult>());
  const [selected, setSelected] = React.useState(0);

  React.useEffect(() => {
    async function fetchIt() {
      const data = await getScheduleCourses();
      setKurses(data);
      setLoaded(true);
    }
    
    fetchIt();
  }, []);

  const handleSelectKurs = React.useCallback((index: number) => {
    setSelected(index);
  }, []);
  
  return loaded ? 
    <div className={styles.container}>
      <div className={styles.title}>
        Расписание:
      </div>
      <div className={styles.rasp_container}>
        <div className={styles.rasp_kurses}>
          {
            <>
              { 
                kurses.map((x, index) => 
                  <div 
                    onClick={() => handleSelectKurs(index)} 
                    className={index === selected ? cn(styles.rasp_kurses_item, styles.rasp_kurses_item_active) : styles.rasp_kurses_item}
                  >
                    {x.kursName}
                  </div>
                ) 
              }
            </>
          }
        </div>
        <div className={styles.rasp_groups_container}>
          <div className={styles.rasp_groups}>
            {
              <>
                { 
                  kurses[selected].groups.map(kurs => 
                    <Link to={`/students/rasp/${kurs.index}`}>
                      <div className={styles.rasp_groups_item}>
                        {kurs.groupName}
                      </div>
                    </Link>
                  )
                }
              </>
            }
          </div>
        </div>
      </div>
    </div> : 
    <div className={styles.container}>
      <Skeleton paragraph={{ rows: 7 }} />
    </div>
}

export default ScheduleCourses;
