import styles from './Navigation.module.css';

import { NavLink} from "react-router-dom";
import { Space } from 'antd';
import { LoginResult } from '../../redux/actions/types';

interface NavigationProps {
  currentUser: LoginResult | null,
}

const Navigation: React.FC<NavigationProps> = (props) => {
  return (
    <div className={styles.navigation}>
      <div className={styles.content}>
        <div className={styles.leftside}>
          <Space>
            <NavLink activeClassName={styles.link_active} className={styles.link} exact to="/">
              Главная
            </NavLink>
            <NavLink activeClassName={styles.link_active} className={styles.link} to="/news">
              Новости института
            </NavLink>
            <NavLink activeClassName={styles.link_active} className={styles.link} to="/students">
              Студентам
            </NavLink>
            <NavLink activeClassName={styles.link_active} className={styles.link} to="/feedback">
              Обратная связь
            </NavLink>
            {
              props.currentUser?.roles?.includes('ROLE_ADMIN') &&
              <NavLink activeClassName={styles.link_active} className={styles.link} to="/admin-panel">
                Панель управления
              </NavLink>
            }
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Navigation;