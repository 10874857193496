import styles from './Header.module.css';
import cn from 'classnames';

import { Space } from 'antd';
import { NavLink } from "react-router-dom";
import { LoginResult } from '../../redux/actions/types';

interface HeaderProps {
  currentUser: LoginResult | null,
  logOutCallback: Function
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <div className={styles.header}>
      <div className={styles.header_content}>
        <div className={styles.leftside}>
          <div className={styles.logo}></div>
        </div>
        <div className={styles.rightside}>
          <Space>
            {
              (props.currentUser?.username) ? 
                <>
                  <NavLink to="/profile">
                    <div className={cn(styles.button, styles.login_button)}> 
                      {props.currentUser.username}
                    </div>
                  </NavLink>
                  <NavLink onClick={props.logOutCallback} to="/login" className={cn(styles.button, styles.register_button)}>
                    Выйти
                  </NavLink>
                </>
                :
                <>
                  <NavLink to="/login">
                    <div className={cn(styles.button, styles.login_button)}> 
                      Вход
                    </div>
                  </NavLink>
                  <NavLink to="/sign-up" className={cn(styles.button, styles.register_button)}>
                    Регистрация
                  </NavLink>
                </>
            }
          </Space>
        </div>
      </div>
    </div>
  );
}

export default Header;