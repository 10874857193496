interface CarouselElementType {
  title: string,
  text: string,
  image_url: string
}

const elementList: CarouselElementType[] = [
  {
    title: 'КЛУБ ЦИФРОВЫХ УПРАВЛЕНЧЕСКИХ ИННОВАЦИЙ СИБГУТИ',
    text: 'Уважаемые студенты! Исполняющий обязанности ректора университета Бари Галимович Хаиров приглашает вас вступить в Клуб цифровых управленческих инноваций и начинает курс открытых лекций для студентов ВО и СПО.',
    image_url: 'https://static.posohin.com/header11.jpg'
  },
  {
    title: 'Встреча выпускников 17 апреля',
    text: `
      УрТИСИ СибГУТИ открывает свои двери для выпускников всех прошлых лет!<br/>
      17 апреля с 11.00 до 14.00 Г. Екатеринбург, ул. Репина, 15.<br/>
      Приходите посмотреть на альма-матер, показать себя и обнять однокурсников!`,
    image_url: 'https://static.posohin.com/header3.jpg'
  },
  {
    title: 'УрТИСИ СибГУТИ завершает юбилейный год!',
    text: 'Уральский технический институт связи и информатики завершает празднование 90-летнего юбилея проведением LXII Межвузовской научно-методической конференции: «Возможности и перспективы цифровой экономики в науке и образовании».',
    image_url: 'https://static.posohin.com/header90.jpg'
  }
];

interface ContactType {
  title: string,
  text: string,
  isAddress: boolean
}

const contactsList: ContactType[] = [
  { 
    title: 'Приёмная комиссия:',
    text: '+7 (343) 242-14-82',
    isAddress: false
  },
  {
    title: 'Приёмная директора:',
    text: '+7 (343) 232-39-02',
    isAddress: false
  },
  {
    title: 'Электронная почта:',
    text: 'urtisi@sibguti.ru',
    isAddress: false
  },
  {
    title: 'Электронная почта:',
    text: 'adm@urtisi.ru',
    isAddress: false
  },
  {
    title: 'Адрес\n Института',
    text: '620109,\n г. Екатеринбург, \n ул. Репина, д. 15.',
    isAddress: true
  }
];

export {
  elementList,
  contactsList
}