import styles from './NewsPage.module.css';
import React from 'react';

import cn from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route, Link } from "react-router-dom";
import { Skeleton } from 'antd';

import { LoginResult } from '../../redux/actions/types';
import { RootState } from '../../redux/store/store';

import ArticlePreview from '../../components/ArticlePreview/ArticlePreview';
import ArticleFull from '../../components/ArticleFull/ArticleFull';
import { getListNews } from '../../services/user.service';
import { NewsArticlePreview } from '../../Interfaces/types';

const SkeletonEmpty = 
  <>
    <div className={styles.skeleton}>
      <Skeleton avatar={{ shape: 'square', size: 'large'}} paragraph={{ rows: 7 }} />
    </div>
    <div className={styles.skeleton}>
      <Skeleton avatar={{ shape: 'square', size: 'large'}} paragraph={{ rows: 7 }} />
    </div>
    <div className={styles.skeleton}>
      <Skeleton avatar={{ shape: 'square', size: 'large'}} paragraph={{ rows: 7 }} />
    </div>
  </>;

interface ArticlePageProps {
  match: {
    url: string,
    params: {
      newId: string
    }
  },
  user: LoginResult | null
}

const NewsPage: React.FC<ArticlePageProps> = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  
  const currentUser = React.useMemo(() => props.user, [props.user]);

  const [articles, setArticles] = React.useState(new Array<NewsArticlePreview>());

  React.useEffect(() => {
    function fetchIt() {
      setLoaded(false);

      getListNews().then(result => {
        setArticles(result);
      }).catch(() => {
        console.log("Ошибка загрузки превью новостей!");
      }).finally(() => {
        setLoaded(true);
      });
    }
    
    fetchIt();
  }, []);

  return (
    <Switch>
      <Route exact path="/news">
        <div className={styles.news_container_title}>
          Последние новости
        </div>
        <div className={styles.news_container}>
          <div className={styles.articles}>
            { 
              loaded ? 
                articles.map(x => 
                  <ArticlePreview key={`article${x.id}`} {...x} />
                ) : SkeletonEmpty 
            }
          </div>
          <div className={styles.aside}>
            <div className={styles.aside_title}>
              Все новости
            </div>
              { 
                loaded ? articles.map(x => 
                  <Link className={styles.link_color} to={`/news/${x.id}`}>
                    <div key={`article${x.id}`} className={styles.aside_post}>
                      {x.title}
                    </div>
                  </Link>
                  ) : <Skeleton paragraph={{ rows: 7 }} />
              }
            </div>
        </div>
      </Route>
      <Route exact path="/news/:newId" render={(propss) => {
        const id = propss.match.params.newId;
        return (
          <>
            <div className={cn(styles.news_container, styles.target_new)}>
              <div className={styles.target_container}>
                <ArticleFull id={id} user={currentUser} />
              </div>
              <div className={cn(styles.aside, styles.target_aside)}>
                <div className={styles.aside_title}>
                  Все новости
                </div>
                { 
                  loaded ? articles.map(x => 
                    <Link className={styles.link_color} to={`/news/${x.id}`}>
                      <div key={`article${x.id}`} className={styles.aside_post}>
                        {x.title}
                      </div>
                    </Link>
                    ) : <Skeleton paragraph={{ rows: 7 }} />
                }
              </div>
          </div>
        </>
        );
      }} />
    </Switch>
  );
};

interface AppState {
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): AppState => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);

