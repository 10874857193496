import styles from './AdminPanelPage.module.css';
import React from 'react';
import cn from 'classnames';

import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Space } from 'antd';
import NewArticlePage from '../NewArticlePage/NewArticlePage';
import ArticlesAdminPanelPage from '../AdminPages/ArticlesAdminPanel/ArticlesAdminPanel';
import FeedbacksAdminPanel from '../AdminPages/FeedbacksAdminPanel/FeedbacksAdminPanel';

const AdminPanelPage: React.FC = () => {
  return (
    <div>
      <div className={styles.profile_navigation}>
        <Space size='middle'>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/admin-panel/add-article">Добавить статью</NavLink>
          </div>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/admin-panel/manage-articles">Управление статьями</NavLink>
          </div>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/admin-panel/feedbacks">Фидбеки</NavLink>
          </div>
        </Space>
      </div>
      <div className={styles.content_container}>
        <Switch>
          <Redirect exact from="/admin-panel" to="/admin-panel/add-article" />

          <Route exact path="/admin-panel/add-article">
            <NewArticlePage />
          </Route>

          <Route exact path="/admin-panel/manage-articles">
            <ArticlesAdminPanelPage />
          </Route>

          <Route exact path="/admin-panel/feedbacks">
            <FeedbacksAdminPanel />
          </Route>

        </Switch>
      </div>
    </div>
  )
}

export default AdminPanelPage;