import React from 'react';
import RaspSelectedGroup from '../../components/ScheduleCoursePairs/ScheduleCoursePairs';
import ScheduleCourses from '../../components/ScheduleCourses/ScheduleCourses';

import { Switch, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom';

const StudentsPage: React.FC = (props) => {
  return (
    <Switch>
      <Redirect exact from="/students" to="/students/rasp" />

      <Route exact path="/students/rasp">
        <ScheduleCourses />
      </Route>

      <Route exact path="/students/rasp/:groupId" render={(props) => {
        const groupId: number = props.match.params.groupId;

        return <RaspSelectedGroup id={groupId} /> 
      } } />
    </Switch>
  );
}

export default StudentsPage;