import { RootState } from '../store/store';
import * as AuthService from './../../services/auth.service';

import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { loginFailAction, loginSuccessAction, logOutAction, registerFailAction, registerSuccessAction, setMessageAction } from "./types";
import { EStatusCode, RequestStatus } from '../../Interfaces/types';

export const doRegister = (username: string, email: string, password: string) => (dispatch) => {
  return AuthService.register(username, email, password).then(
    (response) => {
      dispatch(registerSuccessAction);

      dispatch(setMessageAction(
          {message: 'Успешная регистрация!' }
        ));

      return Promise.resolve();
    },
    (error) => {
      const message: string =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        
      const response: RequestStatus = error.response.data;

      dispatch(registerFailAction);

      dispatch(setMessageAction({ message: message }));

      switch(response.status_code) {
        case EStatusCode.UsernameTaken: 
          return Promise.reject("Имя пользователя уже занято!");

        case EStatusCode.EmailTaken: 
          return Promise.reject("Email уже занят!");

        default: 
          return Promise.reject('UnknownError');
      }
    }
  );
};

export const doLogin = (username: string, password: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  return AuthService.login(username, password).then(
    (response) => {
      if (response.result.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.result));
      }

      dispatch(loginSuccessAction({ user: response.result }));

      dispatch(setMessageAction(
        {message: 'Успешная авторизация!' }
      ));

      return Promise.resolve();
    },
    (error) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const message: string = 
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

        dispatch(loginFailAction);
  
        // dispatch(setMessageAction({ message: message }));
        dispatch(setMessageAction({ message: 'Неверный логин или пароль!' }));

        const response: RequestStatus = error.response.data;

        switch(response.status_code) {
          case EStatusCode.InvalidUsernameOrPassword: 
            return Promise.reject("Неверное имя пользователя или пароль!");
  
          default: 
            return Promise.reject('Неизвестная ошибка!');
        }
      }
    );
};

export const doLogout = () => (dispatch) => {
  AuthService.logout();

  dispatch(logOutAction);
};
