import axios from "axios";
import { RequestStatus } from "../Interfaces/types";

import { API_URL_BASE as API_URL } from './services.config';

const register = (username: string, email: string, password: string) => {
  return axios.post<RequestStatus>(API_URL + "auth/signup", {
    username,
    email,
    password,
  });
};

const login = (username: string, password: string) => {
  return axios
    .post<RequestStatus>(API_URL + "auth/signin", {
      username,
      password,
    })
    .then((response) => {
      return response.data;
    })
};

const logout = () => {
  localStorage.removeItem("user");
};

export {
  register,
  login,
  logout,
};
