import styles from './ProfilePage.module.css';
import React from "react";
import cn from 'classnames';

import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { LoginResult } from '../../redux/actions/types';
import { Space, Form, Input } from 'antd';

import AdminPage from '../UserRoleBasedPages/AdminTestPage/AdminTestPage';
import UserPage from '../UserRoleBasedPages/UserTestPage/UserTestPage';

interface ProfileProps {
  user: LoginResult | null
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const ProfilePage: React.FC<ProfileProps> = (props) => {
  const currentUser = React.useMemo(() => props.user, [props.user]);

  const userToken = React.useMemo(() => {
    if (currentUser?.accessToken) {
      const partOne = currentUser.accessToken.substring(0, 15);
      const partTwo = currentUser.accessToken.substr(currentUser.accessToken.length - 15);

      return `${partOne} ... ${partTwo}`;
    } 
    return '';
  }, [currentUser?.accessToken]);

  const userRoles = React.useMemo(() => {
    if (currentUser?.roles) {
      const roles: string[] = currentUser.roles.map(role => role.replace('ROLE_', '').toLowerCase());

      return roles.join(', ');
    }
    return '';
  }, [currentUser?.roles]);

  if (!currentUser || !currentUser.username) {
    return <Redirect to="/login" />;
  }
  
  return (
    <div>
      <div className={styles.profile_navigation}>
        <Space size='middle'>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/profile">Профиль</NavLink>
          </div>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/profile/adminpage">Права администратора</NavLink>
          </div>
          <div className={styles.profile_navigation_item}>
            <NavLink to="/profile/userpage">Права пользователя</NavLink>
          </div>
        </Space>
      </div>
      <div className={styles.content_container}>
        <Switch>
          <Route exact path="/profile/adminpage">
            <AdminPage />
          </Route>

          <Route exact path="/profile/userpage">
            <UserPage />
          </Route>

          <Route exact path="/profile">
            <Form
              {...layout}
              style={{color: 'red'}}
              name="normal_login"
              className="profile_form"
              initialValues={{ remember: true }}
            >
              <span className={cn(styles.label, styles.title)}>Информация о пользователе:</span>

              <Form.Item className={styles.form_item} label="Имя пользователя:" name="user_name">
                <Input
                  className={styles.profile_input}
                  size="large"
                  style={{ 
                    backgroundColor: 'transparent',
                    width: '300px',
                  }}
                  defaultValue={currentUser.username}
                />
              </Form.Item>

              <Form.Item className={styles.form_item} label="ID пользователя:" name="user_id">
                <Input
                  className={styles.profile_input}
                  size="large"
                  style={{ 
                    backgroundColor: 'transparent',
                    width: '300px',
                  }}
                  defaultValue={currentUser.id}
                />
              </Form.Item>

              <Form.Item className={styles.form_item} label="Токен авторизации:" name="user_token">
                <Input
                  className={styles.profile_input}
                  size="large"
                  style={{ 
                    backgroundColor: 'transparent',
                    width: '300px',
                  }}
                  defaultValue={userToken}
                />
              </Form.Item>
              
              <Form.Item className={styles.form_item} label="Почта:" name="user_email">
                <Input
                  className={styles.profile_input}
                  size="large"
                  style={{ 
                    backgroundColor: 'transparent',
                    width: '300px',
                  }}
                  defaultValue={currentUser.email}
                />
              </Form.Item>

              <Form.Item className={styles.form_item} label="Права в системе:" name="user_roles">
                <Input
                  className={styles.profile_input}
                  size="large"
                  style={{ 
                    backgroundColor: 'transparent',
                    width: '300px',
                  }}
                  defaultValue={userRoles}
                />
              </Form.Item>
            </Form>
          </Route>
        </Switch>
      </div>
    </div>);
};

type RegisterState = {
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): RegisterState => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);