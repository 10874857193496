import axios from "axios";
import authHeader from "./auth-header";

import { API_URL_BASE as API_URL } from './services.config';
import { NewsArticle, EStatusCode, RequestStatus, ScheduleCoursePairsResponce, ScheduleCoursesResult, NewsArticlePreview, Comment, FeedbackType } from "../Interfaces/types";

const getPublicContent = () => {
  return axios.get<RequestStatus>(API_URL + "test/all");
};

const getUserBoard = () => {
  return axios.get<RequestStatus>(API_URL + "test/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get<RequestStatus>(API_URL + "test/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get<RequestStatus>(API_URL + "test/admin", { headers: authHeader() });
};

const sendNewPost = (title: string, previewText: string, posterLink: string, html: string) => {
  return axios.post<RequestStatus>(API_URL + "addPost", {
      title,
      html,
      posterLink,
      previewText
    },
    { headers: authHeader() })
    .then((response) => {
      return response.data;
  });
}

const sendNewComment = (postId, text, authorName) => {
  return axios.post<RequestStatus>(API_URL + "addComment", {
      postId,
      text,
      authorName
    },
    { headers: authHeader() })
    .then((response) => {
      return response.data.result as Comment;
  });
}

// превью всех постов
const getListNews = () => {
  return axios.get<RequestStatus>(
    API_URL + "getListNews", 
    { 
      headers: authHeader() 
    }).then(response => {
      if (response.data.status_code === EStatusCode.OK) {
        return response.data.result as NewsArticlePreview[];
      }
      return Promise.reject();
    });
}

// выбранный пост загружаем полностью
const getSelectedNews = (index: number) => {
  return axios.get<RequestStatus>(
    API_URL + "getSelectedNews", 
    {
      params: {
        id: index
      }
    }).then(response => {
      if (response.data.status_code === EStatusCode.OK) {
        return response.data.result as NewsArticle;
      }
      return Promise.reject();
    });
}

// три последних поста на главной странице
const getLatestNews = () => {
  return axios.get<RequestStatus>(
    API_URL + "getLatestNews", 
    { 
      headers: authHeader()
    }).then(response => {
      if (response.data.status_code === EStatusCode.OK) {
        return response.data.result as NewsArticle[];
      }

      return Promise.reject();
    });
}

const getScheduleCourses = () => {
  return axios.get<RequestStatus>(
    API_URL + "getScheduleCourses"
    ).then(response => {
      if (response.data.status_code === EStatusCode.OK) {
        return response.data.result as ScheduleCoursesResult[];
      }
      return Promise.reject();
    });
}

const getScheduleCoursePairs = (index: number) => {
  return axios.get<RequestStatus>(
    API_URL + 'getScheduleCoursePairs', 
    {
      params: {
        id: index
      }
    }).then(response => {
      if (response.data.status_code === EStatusCode.OK) {
        return response.data.result as ScheduleCoursePairsResponce;
      }
      return Promise.reject();
    });
}

const sendFeedback = (authorName: string, authorEmail: string, text: string) => {
  return axios.post<RequestStatus>(
    API_URL + "sendFeedback", 
    {
      authorName,
      authorEmail,
      text
    },
    { 
      headers: authHeader() 
    })
  .then((response) => {
    return response.data.result;
  });
}

const adminkaGetFeedbacks = () => {
  return axios.get<RequestStatus>(
    API_URL + "adminka/getFeedbacks", 
    { 
      headers: authHeader()
    }).then(response => {
      return response.data.result as FeedbackType[];
    });
}

const adminkaGetArticles = () => {
  return axios.get<RequestStatus>(
    API_URL + "adminka/getArticles", 
    { 
      headers: authHeader()
    }).then(response => {
      return response.data.result as NewsArticle[];
    });
}

const adminkaDeleteArticle = (index) => {
  return axios.get<RequestStatus>(
    API_URL + 'adminka/deleteArticle', 
    {
      headers: authHeader(),
      params: {
        id: index
      }
    }).then(response => {
        return response.data.result;
    });
}

const adminkaDeleteComment = (index) => {
  return axios.get<RequestStatus>(
    API_URL + 'adminka/deleteComment', 
    {
      headers: authHeader(),
      params: {
        id: index
      }
    }).then(response => {
        return response.data.result;
    });
}

export {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  sendNewPost,
  sendNewComment,
  getListNews,
  getSelectedNews,
  getLatestNews,
  getScheduleCourses,
  getScheduleCoursePairs,
  sendFeedback,
  adminkaGetFeedbacks,
  adminkaGetArticles,
  adminkaDeleteArticle,
  adminkaDeleteComment
};
