import styles from './Main.module.css';
import React from 'react';

import { Switch, Route } from "react-router-dom";

import LoginPage from '../../pages/LoginPage/LoginPage';
import RegisterPage from '../../pages/RegisterPage/RegisterPage';
import NewArticlePage from '../../pages/NewArticlePage/NewArticlePage';
import NewsPage from '../../pages/NewsPage/NewsPage';
import ProfilePage from '../../pages/ProfilePage/ProfilePage';
import CarouselElement from '../CarouselElement/CarouselElement';
import LatestNews from '../LatestNews/LatestNews';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import StudentsPage from '../../pages/StudentsPage/StudentsPage';
import FeedbackPage from '../../pages/FeedbackPage/FeedbackPage';
import AdminPanelPage from '../../pages/AdminPanelPage/AdminPanelPage';

const Main: React.FC = () => {
  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <Switch>
          <Route exact path="/">
            <CarouselElement />
            <LatestNews />
          </Route>
          <Route path="/news" component={NewsPage} />

          <Route path="/students" component={StudentsPage} />

          <Route path="/admin-panel" component={AdminPanelPage}></Route>

          <Route exact path="/login">
            <LoginPage />
          </Route>

          <Route exact path="/sign-up">
            <RegisterPage />
          </Route>

          <Route path="/profile">
            <ProfilePage />
          </Route>

          <Route path="/feedback">
            <FeedbackPage />
          </Route>

          <Route component={NotFoundPage} />

        </Switch>
      </div>
    </div>
  );
}

export default Main;