import styles from './NewArticlePage.module.css';
import React from 'react';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { Redirect } from 'react-router-dom';

import cn from 'classnames';
import { Form, Input, Button } from 'antd';

import { FormInstance } from 'antd/lib/form';

import { ToastContainer, toast } from 'react-toastify';

import { sendNewPost } from './../../services/user.service';
import { LoginResult } from '../../redux/actions/types';
import { RootState } from '../../redux/store/store';
import { connect } from 'react-redux';
import { getArticlePreviewText } from '../../Utils';

const { TextArea } = Input;

interface NewData {
  title: string,
  posterLink: string
}

interface NewArticlePageProps {
  user: LoginResult | null
}

const NewArticlePage: React.FC<NewArticlePageProps> = (props) => {
  const currentUser = React.useMemo(() => props.user, [props.user]);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const formRef = React.useRef<FormInstance>(null);
  const [previewText, setPreviewText] = React.useState('');

  const editorRef = React.useRef<any>(null);

  const html = React.useMemo(() => {
    const editorCurrentContent = editorState.getCurrentContent();

    const plainText = editorCurrentContent.getPlainText('');
    
    setPreviewText(getArticlePreviewText(plainText));

    return draftToHtml(convertToRaw(editorCurrentContent));
  }, [editorState]);

  const onFinish = (values: NewData) => {
    sendNewPost(values.title, previewText, values.posterLink, html).then((r) => {
      toast("Новость успешно опубликована!");
    }).catch(() => {
      toast("Ошибка публикации новости!");
    });
  }

  const onEditorStateChange = (state) => {
    setEditorState(state);
  }

  const setDefaultPosterLink = () => {
    formRef.current?.setFieldsValue({
      posterLink: 'https://static.posohin.com/noposter.png'
    });
  }

  const handlePastedText = React.useCallback(() => false, [])

  if (!currentUser || !currentUser.username) {
    return <Redirect to="/login" />;
  }

  if (!currentUser.roles.includes('ROLE_ADMIN')) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.page}>
      <Form
        ref={formRef}
        style={{color: 'red'}}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <span className={cn(styles.label, styles.title)}>
          Добавить новую статью
        </span>

        <span className={styles.label}>
          Заголовок новости:
        </span>  
        <Form.Item name="title" rules={[{ required: true, message: 'Пожалуйста введите имя новости!' }]} >
          <Input
            size="large" 
            placeholder="" 
          />
        </Form.Item>

        <span className={styles.label}>Ссылка на постер (например <span onClick={setDefaultPosterLink}><u>вот эта</u></span>):</span>  
        <Form.Item name="posterLink" rules={[{ required: true, message: 'Пожалуйста введите ссылку на постер!' }]} >
          <Input
            size="large" 
            placeholder="" 
          />
        </Form.Item>

        <span className={styles.label}>
          Редактор:
        </span>
        <div className={styles.editor_container}>
          <Editor
            ref={editorRef}
            editorClassName={styles.editor}
            handlePastedText={handlePastedText}
            onEditorStateChange={onEditorStateChange}
          />
        </div>

        <br />
        <span className={styles.label}>Превью статьи (первые 100 символов):</span>
        <TextArea
          rows={1}
          style={{ resize: 'none' }}
          value={previewText} 
        />
        
        <br /><br />
        <span className={styles.label}>HTML:</span>
        <TextArea
          rows={4}
          style={{ resize: 'none' }}
          value={html} 
        />
        
        <br />
        <br />
        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Button style={{ background:'linear-gradient(180deg, #005FAA 0%, rgba(28, 101, 188, 0.88) 100%)' }} size="large" type="primary" htmlType="submit" className="login-form-button">
            Опубликовать
          </Button>
        </Form.Item>
      </Form>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

type LoginState = {
  message: string,
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): LoginState => ({
  message: state.message,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(NewArticlePage);