import React from "react";
import { getUserBoard } from "../../../services/user.service";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const UserPage = () => {
  const [content, setContent] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(false);

    getUserBoard().then(
      (response) => {
        setContent('Этот текст могут видеть все авторизованные пользователи');
      },
      ).catch(() => {
        setContent("Ошибка запроса");
      }).finally(() => {
        setLoaded(true);
      });

  }, []);

  return (
    <div>
      { loaded ? <span>{ content }</span> : <Spin indicator={antIcon} /> }
    </div>
  );
};

export default UserPage;
