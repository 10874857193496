// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './App.module.css';
import React from 'react';

import { connect } from 'react-redux';
import { doLogout } from './redux/actions/auth';
import { RootState } from './redux/store/store';
import { LoginResult } from './redux/actions/types';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import Navigation from './components/Navigation/Navigation';

import { History } from 'history';

import { withRouter } from 'react-router-dom';
import { clearMessage } from './redux/actions/message';

import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  user: LoginResult | null,
  doLogout: () => Promise<void>,
  clearMessage: () => void,
  history: History
}

const App: React.FC<AppProps> = (props) => {
  props.history.listen(() => {
    props.clearMessage();
  });
  
  const currentUser = React.useMemo(() => props.user, [props.user]);

  React.useEffect(() => {
    document.title = 'Уральский технический институт связи и информатики';
  }, []);

  const onLogout = () => {
    props.doLogout();
  };

  return (
    <>
      <Header currentUser={ currentUser } logOutCallback={onLogout} />

      <Navigation currentUser={ currentUser } />

      <Main />

      <Footer />
    </>
  );
}

interface AppState {
  user: LoginResult | null
}

const mapStateToProps = (state: RootState): AppState => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  doLogout: () : Promise<void> => dispatch(doLogout()),
  clearMessage: (): void => dispatch(clearMessage)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
