import React from "react";
import { getAdminBoard } from "../../../services/user.service";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const AdminPage = () => {
  const [content, setContent] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(false);

    getAdminBoard().then(
      (response) => {
        setContent('Этот текст могут видеть только пользователи с правами администратора.');
      }
    ).catch(() => {
      setContent("Ошибка запроса");
    }).finally(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <div>
      { loaded ? <span>{ content }</span> : <Spin indicator={antIcon} /> }
    </div>
  );
};

export default AdminPage;
